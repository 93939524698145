<template>
  <div class="card">
    <div class="card-wrap">
      <div v-for="item in items" :key="item.id" class="card-item">
        <img :src="item.image | normalizeImg('thumb', 'ED', 'ED')" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  import normalizeImg from '@/filters/normalizeImg';

  export default {
    name: 'VerticalItemType',
    props: {
      items: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    methods: {
      normalizeImg,
    },
  }
</script>
<style lang="scss" scoped>
  .card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 159px;
    height: 159px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(151, 174, 202, 0.4);
  }

  .card-wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    align-content: center;
    gap: 4px;
    width: 132px;
    height: 136px;
  }

  .card-item {
    width: 29px;
    height: 30px;
    background-color: rgba(#000, 0.2);
    border-radius: 5px;
    overflow: hidden;

    &:nth-of-type(2n) {
      margin-top: -10px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>
