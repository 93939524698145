<template>
  <div class="categories-wrap">
    <div class="categories-items">
      <div v-for="item in items" :key="item.id">
        <img :src="item.image | normalizeImg('thumb', 'ED', 'ED')" class="categories-img">
      </div>
    </div>
  </div>
</template>

<script>
  import normalizeImg from '@/filters/normalizeImg';

  export default {
    name: 'CircleItemType',
    props: {
      items: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    methods: {
      normalizeImg,
    },
  }
</script>
<style lang="scss" scoped>
  .categories-wrap {
    border-radius: 50%;
    box-shadow: 0px 0px 20px rgba(151, 174, 202, 0.4);
    width: 135px;
    height: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .categories-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
    grid-gap: 7px;
    transform: translate(0, -8%);

    div {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;
      background-color: rgba(#000, 0.2);

      &:nth-child(1) {
        grid-column: 1;
        grid-row: 2;
        transform: translate(-15%, -33%);
      }

      &:nth-child(2) {
        grid-column: 2;
        grid-row: 1;
        transform: translate(0%, 0%);
      }

      &:nth-child(3) {
        grid-column: 3;
        grid-row: 2;
        transform: translate(15%, -34%);
      }

      &:nth-child(4) {
        grid-column: 2;
        grid-row: 2;
        transform: translate(0%, 20%);
      }

      &:nth-child(5) {
        grid-column: 1;
        grid-row: 3;
        transform: translate(0%, -11%);
      }

      &:nth-child(6) {
        grid-column: 2;
        grid-row: 3;
        transform: translate(0%, 41%);
      }

      &:nth-child(7) {
        grid-column: 3;
        grid-row: 3;
        transform: translate(-2%, -15%);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .open-categories-link {
    margin-top: 22px;

    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
    }

    img{
      margin-left: 10px;
    }
  }
</style>
